'use strict';

/**
 * Create an alert to display the error message
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>' + message + '</div>';

    $('.error-messaging').append(errorHtml);
}

module.exports = function () {
    var searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('offerCurrencyError')) {
        createErrorNotification(searchParams.get('offerCurrencyError'));
    }
};
