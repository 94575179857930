'use strict';

var base = require('base/product/base');
var quantityWidgetHelpers = require('./quantity');
var powerReview = require('../product/powerReview');

window.jqueryzoom = window.jqueryZoom = require('jquery-zoom/jquery.zoom.min');
// var pinchZoom = require('pinch-zoom-js/src/pinch-zoom');
var imagesLoaded = require('imagesloaded');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
 function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').data('sku-id');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelector;
    if ($el && $('.set-items').length) {
        quantitySelector = $($el).closest('.product-detail').find('.quantity-input');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-input');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-input');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelector = quantitySelectedPDP;
        } else {
            quantitySelector = quantitySelectedModal;
        }
    } else {
        quantitySelector = $('.quantity-input');
    }
    return quantitySelector;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color', 'size', 'cardType', 'cardAmount'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
            if (attr.id == 'color') {
                $productContainer.find('.attr-color-value').text(attr.displayValue.toLowerCase());
            } else if(attr.id == 'size') {
                $productContainer.find('.attr-size-value').text(attr.displayValue.toLowerCase());
            } else if(attr.id == 'cardType') {
                $productContainer.find('.attr-cardtype-value').text(attr.displayValue.toLowerCase());
            } else if(attr.id == 'cardAmount') {
                $productContainer.find('.attr-cardamount-value').text(attr.displayValue.toLowerCase());
            }
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div class="select-styles">' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += `<li><div${message.includes('adjust the quantity') ? ' class="select-styles"' : ''}>${message}</div></li>`;
        });
        var isAvailable = response.product && response.product.available;

        if (isAvailable) {
            setTimeout(function() {
                $('.quantity-wrapper').removeClass('d-none');
            }, 10)
        } else {
            setTimeout(function() {
                $('.quantity-wrapper').addClass('d-none');
            }, 10)
        }

        if (response.product.readyToOrder && isAvailable) {
            var country = $('.pdp-buybox').data('country') || $('.custom-quantity-selector').data('country');
            var is3PInternationalShipping = country !== 'US' && response.product.marketplaceProduct
            if (!is3PInternationalShipping) {
                setTimeout(function() {
                    var currentQuantity = $('.quantity-input').val();
                    $('.custom-quantity-selector').removeAttr('disabled').removeClass('disabled');

                    if (response.product.maxOrderQuantity > currentQuantity) {
                        $('.quantity-increase').removeAttr('disabled');
                        $('.quantity-increase').removeClass('disabled disabled-btn');
                    }

                    if (currentQuantity > response.product.minOrderQuantity) {
                        $('.quantity-decrease').removeAttr('disabled');
                        $('.quantity-decrease').removeClass('disabled disabled-btn');
                    }
                    if (currentQuantity == '0') {
                        $('.quantity-input').val(response.product.minOrderQuantity);
                    }
                    $('.quantity-input').removeClass('disabled disabled-btn');
                    $('.quantity-input').removeAttr('disabled');
                }, 10);
            } else {
                setTimeout(function() {
                    $('.custom-quantity-selector').attr('disabled', true).addClass('disabled');
                    $('.quantity-decrease').attr('disabled', true).addClass('disabled-btn');
                    $('.quantity-decrease').attr('disabled', true).addClass('disabled-btn');
                    $('.quantity-input').attr('disabled', true).addClass('disabled');
                }, 10);
            }
        }
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
	// Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    var thumbNailShow = $productContainer.find('.primary-images').data('thumbnailshow');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev d-md-none" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next d-md-none" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a><ol class="carousel-indicators ' + (!thumbNailShow ? 'hidden-md-down' : '') + '"></ol>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item desktop-zoom"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    $('.vatiant-prod-id').html(response.product.id);
    var wishlistIcon = "<i class='fa fa-stack-1x fa-heart-o'></i>";
    if(response.isAddedToWishlist){
        wishlistIcon = "<i class='fa fa-stack-1x fa-heart'></i>";
        $('.add-to-wish-list span').empty().append(wishlistIcon);
    } else {
        $('.add-to-wish-list span').empty().append(wishlistIcon);
    }
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global, button.add-to-cart-egift').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(product, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var $quantitySelector = getQuantitySelector($productContainer);
        $quantitySelector.data('max', product.maxOrderQuantity);
        $quantitySelector.data('min', product.minOrderQuantity);
        $quantitySelector.data('url', product.quantityWidget && product.quantityWidget.url);
        $quantitySelector.data('step', product.quantityWidget && product.quantityWidget.stepQuantity);

        var $warningSelector = $('.max-quantity-warning');
        var $errorSelector = $('.max-quantity-error');
        var currentEnteredValue = Number($quantitySelector.val());

        $warningSelector.text(product.maxOrderQuantity);
        $errorSelector.text(product.maxOrderQuantity);

        if (currentEnteredValue == product.maxOrderQuantity) {
            $('.quantity-warning').removeClass('d-none').addClass('d-block');
        } else {
            $('.quantity-warning').removeClass('d-block').addClass('d-none');
        }

        if (currentEnteredValue > product.maxOrderQuantity) {
            $('.quantity-error').removeClass('d-none').addClass('d-block');
        } else {
            $('.quantity-error').removeClass('d-block').addClass('d-none');
        }

        // Refresh enabled / disabled states 
        var $quickView = $('.quick-view-dialog');
        var isQuickView = $quickView.length > 0;
        quantityWidgetHelpers.updateQuantityButtonsAndError($productContainer, hasErrors(isQuickView ? $quickView : null, product), isQuickView ? $quickView : null);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                var orderable = data && data.product && data.product.readyToOrder && data.product.available;
                var $addToCartContainer =  $('.add-to-cart');
                $addToCartContainer.data('orderable', orderable);
                if (!orderable) {
                    $addToCartContainer.addClass('disabled').attr('disabled', true);
                } else {
                    var hasQuantityError = $('.quantity-error.d-block').length > 0;
                    if (!hasQuantityError) { 
                        $addToCartContainer.removeClass('disabled').attr('disabled', false);
                     }
                }

                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                loadZoom();
                setTimeout(function() {
                    powerReview.checkPowerReviewAvailability();
                }, 100);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if(response.error){
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    } else {
        getModalHtmlElement();
        var $targetModal = $('#cart-modal');
        fillModalElement(response, $targetModal);
    }
}

function fillModalElement(data, $targetModal){
    if ($targetModal && $targetModal.length > 0) {
        $targetModal.find('.modal-body').html(data);
    } else {
        $('.modal-body').html(data);
    }
    var miniCartResponse = {
        'quantityTotal':parseInt($('#mini-cart-quantity').val()),
        'minicartCountOfItems': parseInt($('#mini-cart-quantity').val()) + ' items in the cart'
    }
    $('.minicart').trigger('count:update', miniCartResponse);
    $('#cart-modal').modal('show');
}

function getModalHtmlElement() {
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="cart-modal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog add-to-bag-dialog modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link d-none" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span class="close-icon"></span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
* PDP Zoom initialization Event
* @param {container} container the element
*/
function initializeZoom(container) {
    imagesLoaded(container).on('always', function () {
        var $container = $(container);
        $(container).trigger('zoom.destroy');
        $(container).find('.zoomImg').remove();
        $container.zoom();
    });
}

/**
* PDP loadZoom Event
*/
function loadZoom() {
    var imgZoom = '.primary-images .desktop-zoom';
    var container = imgZoom;
    imagesLoaded(container).on('always', function () {
        var $container = $(container);
        $(container).trigger('zoom.destroy');
        $(container).find('.zoomImg').remove();
        $container.zoom();
    });
}

/**
 * color attributes change event
 */
 function colorAttribute() {
    $(document).on('click', '[data-attr="color"] button, [data-attr="size"] button, [data-attr="cardType"] button, [data-attr="cardAmount"] button', function (e) {
        e.preventDefault();

        if ($(this).attr('disabled')) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        attributeSelect($(this).attr('data-url'), $productContainer);
    });
}

function objectifyForm(formArray) {
    var returnArray = {};
    for (var i = 0; i < formArray.length; i++){
        returnArray[formArray[i]['name']] = formArray[i]['value'];
    }
    return returnArray;
}

base.addToCart = function () {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global, button.add-egift-button', function (e) {
        e.preventDefault();
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;

        $('body').trigger('product:beforeAddToCart', this);

        pid = getPidValue($(this));

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').data('sku-id'),
                        qty: $(this).find('.quantity-input').val(),
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
            // Product Set ID for cart modal
            pid = $(this).closest('.product-set-detail').data('pid');
        }


        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: getQuantitySelected($(this))
        };

        var gcType = $('#giftType').val();

        if (gcType) {
            var formDataArray = $('#dwfrm_giftcard' + gcType).serializeArray();
            var form = objectifyForm(formDataArray);
            form.pid = pid;
            form.pidsObj = pidsObj;
            form.childProducts = getChildProducts();
            form.quantity = 1;
        }

        if(gcType == 'electronic') {
            addToCartUrl = $(this).data('action');
        }

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (data.fieldError && data.errorMessages) {
                        var errorMessages = data.errorMessages;
                        for (var name in errorMessages) {
                            var fieldElement = $('[name*='+ name +']');
                            fieldElement.removeClass('has-success');
                            fieldElement.addClass('is-invalid');
                            fieldElement.parents('.form-group').find('.invalid-feedback').text(errorMessages[name]);
                        }
                        $.spinner().stop();
                        $('.quantity-input').val('1');
                        return;
                    }
                    handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

var hasErrors = function($container, product) {
    return function() {
        var orderable = true;
        var $addToCartContainer =  $('.add-to-cart');
        if ($addToCartContainer) {
            orderable = $addToCartContainer.data('orderable');
        }
        if ($container && $container.find('.update-cart-product-global').length > 0) {
            if (product && product.available && product.readyToOrder) {
                orderable = true;
            }
        }
        if ($container) {
            return $container.find('.select-styles, .quantity-error.d-block, .third-party-not-available').length > 0 || !orderable;
        }
        return $('.select-styles, .quantity-error.d-block, .third-party-not-available').length > 0 || !orderable;
    }
}

function getProductContainer(context) {
    var $productContainer = $(context).closest('.product-detail');
    if (!$productContainer.length) {
      $productContainer = $(context).closest('.modal-content').find('.product-quickview');
    }
    return $productContainer;
}

function registerAttributeSelection(attributSelectionFn, $quantityInput, $productContainer) {
    var url = $quantityInput.data('url') + '&quantity=' + $quantityInput.val();
    attributSelectionFn(url, $productContainer);
}

base.availability = function () {
    var debouncedCallback = quantityWidgetHelpers.getDebouncedFn(attributeSelect);

    $('body').on('keydown', '.quantity-input', function (e) {
        quantityWidgetHelpers.preventInvalidInput(e);
    });

    $('body').on('blur', '.quantity-input', function (e) {
        var $this = $(this);
        quantityWidgetHelpers.resetOnBlur($this);

    });

    $(document).on('change', '.quantity-input', function (e) {
      e.preventDefault();
      var $productContainer = getProductContainer(this);
      var quantity = quantityWidgetHelpers.getCurrentQuantity(this);
      var maxQuantity = quantityWidgetHelpers.getMaxQuantity(this);
  
      if (quantity < 1) {
        quantityWidgetHelpers.setQuantiy(this, 1);
      }

      var $quickView = $('.quick-view-dialog');
      var isQuickView = $quickView.length > 0;
  
      quantityWidgetHelpers.updateQuantityButtonsAndError($productContainer, hasErrors(isQuickView ? $quickView : null), isQuickView ? $quickView : null);
      $('body').trigger('quantity:update');
  
      if ($('.bundle-items', $productContainer).length === 0 && quantity <= maxQuantity) {
        if($(this).hasClass('offer-quantity-select')) {
            $(this).trigger('offerQuantity:change');
        } else {
            registerAttributeSelection(debouncedCallback, $(e.currentTarget), $productContainer);
        }
      }
    });
  
    $(document).on('click', '.quantity-increase', function (e) {
      e.preventDefault();
      var $productContainer = getProductContainer(this);
  
      if ($('.bundle-items', $productContainer).length === 0) {
        var $quantityInput = $productContainer.find('.quantity-input');
        var step = quantityWidgetHelpers.getStep($quantityInput);
        var maxQuantity = quantityWidgetHelpers.getMaxQuantity($quantityInput);
        var currentQuantity = quantityWidgetHelpers.getCurrentQuantity($quantityInput);
  
        if (currentQuantity < maxQuantity) {
          var newQuantity = parseInt($quantityInput.val(), 10) + step;
          quantityWidgetHelpers.setQuantiy($quantityInput, newQuantity);
          $('body').trigger('quantity:update');
          if($(this).hasClass('offer-quantity')) {
              $('.offer-quantity-select').trigger('offerQuantity:change');
          } else {
              registerAttributeSelection(debouncedCallback, $quantityInput, $productContainer);
          }
        }

        var $quickView = $('.quick-view-dialog');
        var isQuickView = $quickView.length > 0;
  
        quantityWidgetHelpers.updateQuantityButtonsAndError($productContainer, hasErrors(isQuickView ? $quickView : null), isQuickView ? $quickView : null);
      }
    });
  
    $(document).on('click', '.quantity-decrease', function (e) {
      e.preventDefault();
      var $productContainer = getProductContainer(this);
  
      if ($('.bundle-items', $productContainer).length === 0) {
        var $quantityInput = $productContainer.find('.quantity-input');
        var step = quantityWidgetHelpers.getStep($quantityInput);
        var currentQuantity = quantityWidgetHelpers.getCurrentQuantity($quantityInput);
        var maxQuantity = quantityWidgetHelpers.getMaxQuantity($quantityInput);
  
        if (currentQuantity > 1) {
          var newQuantity = parseInt($quantityInput.val(), 10) - step;
          quantityWidgetHelpers.setQuantiy($quantityInput, newQuantity);
          $('body').trigger('quantity:update');
          if (newQuantity <= maxQuantity) {
            if($(this).hasClass('offer-quantity')) {
                $('.offer-quantity-select').trigger('offerQuantity:change');
            } else {
                registerAttributeSelection(debouncedCallback, $quantityInput, $productContainer);
            }
          }
        }

        var $quickView = $('.quick-view-dialog');
        var isQuickView = $quickView.length > 0;
  
        quantityWidgetHelpers.updateQuantityButtonsAndError($productContainer, hasErrors(isQuickView ? $quickView : null), isQuickView ? $quickView : null);
      }
    });
}

base.colorAttribute = function () {
    $(document).on('click', '[data-attr="color"] button, [data-attr="size"] button, [data-attr="cardType"] button, [data-attr="cardAmount"] button', function (e) {
        e.preventDefault();

        if ($(this).attr('disabled') || $(this).find('.selected').length != 0) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        attributeSelect($(this).attr('data-url'), $productContainer);
    });
};

base.loadZoom = loadZoom();

module.exports = base;
