'use strict';
var util = require('./util');

var slickRun = {

    verticalSlider: function () {
        $('.vertical-carousel .tiles-container').not('slick-initialized').each(function (idx) {
            // vertical grid-tiles must be the same height or they get cut off; known issue in slick
            $('.vertical-carousel .tiles-container .grid-tile').syncHeight();
            var sliderID = 'slider' + idx;
            this.id = sliderID;
            $(this).on('init', function() {
                // after initing the slider, advance it one for UI adjustments
                slickRun.sliderOnInit($(this));
            }).slick({
                slide: '#' + sliderID + ' .grid-tile',
                infinite: true,
                arrows: true,
                verticalSwipe: true,
                prevArrow: '<a class="slider-prev">' + util.svg('Arrow_small', 'up-arrow') + '</button>',
                nextArrow: '<a class="slider-next">' + util.svg('Arrow_small', 'down-arrow') + '</button>',
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 3,
                slidesToScroll: 3,
                vertical: true,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<a class="slider-prev">' + util.svg('Arrow_small', 'up-arrow') + '</a>',
                            nextArrow: '<a class="slider-next">' + util.svg('Arrow_small', 'down-arrow') + '</a>'
                        }
                    }
                ]
            });
        });
    },
    horizontalSlider: function () {
        //SHOP THE LOOK
        $('.product-horizontal-carousel').not('slick-initialized').each(function (idx) {
            var sliderID = 'slider' + idx;
            this.id = sliderID;
            $(this).on('init', function() {
                // after initing the slider, advance it one for UI adjustments
                //slickRun.sliderOnInit($(this));
            }).slick({
                slide: '#' + sliderID + ' .grid-tile',
                infinite: true,
                arrows: true,
                swipe: true,
                accessibility: false,
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 4,
                slidesToScroll: 4,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                //slickRun.sliderAfterChange($(this));
            })
        });
    },
    quickViewRecommendations: function () {
        // vertical grid-tiles must be the same height or they get cut off; known issue in slick
        $('.quickViewDialog .carousel-recommendations .tiles-container .grid-tile').syncHeight();
        var quickviewRecom = $('.quickViewDialog .product-recommendations .carousel-recommendations .tiles-container');
        if (quickviewRecom.length) {
            quickviewRecom.not('slick-initialized').on('init', function() {
                // after initing the slider, advance it one for UI adjustments
                slickRun.sliderOnInit($(this));
            }).slick({
                infinite: true,
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 2,
                slidesToScroll: 2,
                vertical: true,
                accessibility: false,
                rows: 0,
                slide: '.quickViewDialog  .product-recommendations .carousel-recommendations .grid-tile',
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow_small') + '</a>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow_small') + '</a>'
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        }
    },
    accoundDashWhoiamShopFor: function () {
        $('.dashboard .whoimshoppingfor-info .block-body .body').not('slick-initialized').slick({
            infinite: true,
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: false,
            slide: '.dashboard .whoimshoppingfor-info .block-body .body .shoppingfor-item',
            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow_small', 'left-arrow') + '</a>',
            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow_small', 'right-arrow') + '</a>'
        });

    },
    confirmationyouwilllove: function () {
        var confirmyoulove =$('.confirmation-we-think-you-love-this .product-recom-slider .tiles-container')
        confirmyoulove.on('init', function() {
            // after initing the slider, advance it one for UI adjustments
            slickRun.sliderOnInit($(this));
        }).not('slick-initialized').slick({
            infinite: true,
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            slidesToShow: 4,
            slidesToScroll: 4,
            accessibility: false,
            slide: '.confirmation-we-think-you-love-this .product-recom-slider .tiles-container .grid-tile',
            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next" >' + util.svg('Arrow', 'medium') + '</button>'
                    }
                }
            ]
        }).on('afterChange', function() {
            slickRun.sliderAfterChange($(this));
        });
    },
    accoundDashWishList: function () {
        if ($('.dashboard .wishlist-info .block-body  .body').length) {
            $('.dashboard .wishlist-info .block-body .body').not('slick-initialized').slick({
                infinite: true,
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 1,
                slidesToScroll: 1,
                accessibility: false,
                slide: '.dashboard .wishlist-info .block-body .body .wishlist-item',
                prevArrow: '<a class="slider-prev">' + util.svg('Arrow_small', 'left-arrow') + '</a>',
                nextArrow: '<a class="slider-next">' + util.svg('Arrow_small', 'right-arrow') + '</a>'

            });
        }
    },
    pdpRecommendations: function () {
        var moreToLoveSlider = $('.pdprow1 .product-recommendations .carousel-recommendations .tiles-container');
        if (moreToLoveSlider.length) {
            moreToLoveSlider.not('slick-initialized').on('init', function() {
                slickRun.sliderOnInit($(this));
            }).slick({
                infinite: true,
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 2,
                slidesToScroll: 2,
                vertical: true,
                arrow: true,
                accessibility: false,
                slide: '.pdprow1 .product-recommendations .grid-tile',
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previuos Slide">' + util.svg('Arrow_small', 'up-arrow') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow_small', 'down-arrow') + '</button>',
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            arrow: true,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previuos Slide">' + util.svg('Arrow_small', 'up-arrow') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow_small', 'down-arrow') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            vertical: false,
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrow: true,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previuos Slide">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        }
    },
    pdpProductCompleteTheLook: function () {
        var theProductLook = $('.product-complete-the-look  .carousel-recommendations .tiles-container');
        if (theProductLook.length) {
            theProductLook.not('.slick-initialized').on('init', function() {
                slickRun.sliderOnInit($(this));
            }).slick({
                infinite: true,
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 4,
                slidesToScroll: 4,
                accessibility: false,
                slide: '.product-complete-the-look .carousel-recommendations .grid-tile',
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previuos Slide">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previuos Slide">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previuos Slide">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        }
    },
    pdpWeThinkYouLoveThis: function () {
        var pdpweThinkSlider = $('.product-we-think-you-love-this .carousel-recommendations .tiles-container');
        if (pdpweThinkSlider.length) {
            pdpweThinkSlider.not('.slick-initialized').on('init', function() {
                slickRun.sliderOnInit($(this));
            }).slick({
                infinite: true,
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 4,
                slidesToScroll: 4,
                accessibility: false,
                slide: '.product-we-think-you-love-this .carousel-recommendations .grid-tile',
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        }
    },
    pdpCompleteLook: function () {
        var pdpCompleteLookSlider = $('.product-complete-the-look .custom-carousel .tiles-container');
        if (pdpCompleteLookSlider.length) {
            pdpCompleteLookSlider.not('.slick-initialized').on('init', function() {
                slickRun.sliderOnInit($(this));
            }).slick({
                infinite: true,
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: 4,
                slidesToScroll: 4,
                accessibility: false,
                slide: '.product-complete-the-look .custom-carousel .grid-tile',
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        }
    },
    addToCartModalRecommendations: function () {
        var addToCart = $('.add-to-cart-modal-recommendations .tiles-container');
        addToCart.not('slick-initialized').on('init', function() {
            slickRun.sliderOnInit($(this));
        }).slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            vertical: false,
            accessibility: false,
            slide: '.add-to-cart-modal-recommendations .grid-tile',
            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                }
            ]
        }).on('afterChange', function() {
            slickRun.sliderAfterChange($(this));
        });

    },
    /**
     * @function heroSlider()
     * @description Initializes the hero slider
     */
    heroSlider: function () {
        var videoSlides = $('.heroSlider').find('.amp-cms-video');
        $('.heroSlider').on('init', function() {
            //if first slide is video, play it
            if (videoSlides.length && $('.slick-active').hasClass('amp-cms-video')) {
                $('.slick-active').find('video').get(0).play();
            }
            $(this).find('.slick-list').removeAttr('aria-live');
            $(this).find('.slick-dots li').each(function(index) { 
                $(this).find('button').attr('aria-label','slide ' + (index+1));
            })
            $(this).find('.slick-arrow .visually-hidden').attr('aria-hidden',true);
            //Removing tabindex for the invisible items
            var slickInvisibleItems = $(this).find('.slick-slide:not(.slick-active) a');
            setTimeout(function() {
                slickInvisibleItems.each(function() {
                    $(this).attr('tabindex',-1);
                });
            }, 100);
        }).on('afterChange', function() {
            //play or pause based on visibility
            if (videoSlides.length) {
                //pause playing videos
                $('.heroSlider').find('video').get(0).pause();
                // play active video
                if ($('.slick-active').hasClass('amp-cms-video')) {
                    $('.slick-active').find('video').get(0).play();
                }
            }
            $(this).find('.slick-list').removeAttr('aria-live');
            $(this).find('.slick-dots li').each(function(index) { 
                $(this).find('button').attr('aria-label','slide ' + (index+1));
            })
            var slickInvisibleItems = $(this).find('.slick-slide:not(.slick-active) a');
            setTimeout(function() {
                slickInvisibleItems.each(function() {
                    $(this).attr('tabindex',-1);
                });
            }, 100);
        }).slick({
            slide: '.heroSlide',
            adaptiveHeight: $('.heroSlider').data('adaptiveHeight'),
            //infinite: $('.heroSlider').data('infinite'),  /** commented to allow the infinite scrolling **/
            infinite: true,
            autoplay: $('.heroSlider').data('autoplay'),
            speed: $('.heroSlider').data('speed'),
            arrows: $('.heroSlider').data('arrows'),
            dots: $('.heroSlider').data('dots'),
            swipe: true,
            fade: $('.heroSlider').data('fade'),
            prevArrow: $('.heroSlider .slider-prev'),
            nextArrow: $('.heroSlider .slider-next'),
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0
        });
    },
    homeAssetProductSlider: function () {
        var hpAssetProduct3 = $('#hp-asset-product-3 .tiles-container');
        hpAssetProduct3.on('init', function() {
            slickRun.sliderOnInit($(this));
        }).slick({
            slide: '#hp-asset-product-3 .grid-tile',
            infinite: true,
            arrows: false,
            swipeToSlide: true,
            touchThreshold: 10,
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            slidesToShow: 4,
            slidesToScroll: 4,
            accessibility: false,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: true,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: true,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                }
            ]
        }).on('afterChange', function() {
            slickRun.sliderAfterChange($(this));
        });
    },
    /**
     * @function productRecomSlider()
     * @description Initializes the product recommendations slider
     * @param Number of Slides to Show in Desktop
     */
    productRecomSlider: function (slidesToShow) {
        $('.product-recom-slider .tiles-container').each(function (idx) {
            var sliderID = 'slider' + idx;
            this.id = sliderID;
            var visibleSlides = slidesToShow ? slidesToShow : 4;
            $(this).on('init', function() {
                slickRun.sliderOnInit($(this));
            }).slick({
                slide: '#' + sliderID + ' .grid-tile',
                infinite: true,
                arrows: true,
                swipe: true,
                accessibility: false,
                prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: visibleSlides,
                slidesToScroll: visibleSlides,
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: (visibleSlides - 1),
                            slidesToScroll: (visibleSlides - 1),
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        });
    },
    /**
     * @function productRecomSlider()
     * @description Initializes the product recommendations slider
     * @param Number of Slides to Show in Desktop
     */
    productRecomSlider2: function (slidesToShow) {
        //WE THINK YOU'LL LOVE THESE
        $('.hp-reco-banner-5 .product-recom-slider .tiles-container').each(function (idx) {
            var sliderID = 'slider' + idx;
            this.id = sliderID;
            var visibleSlides = slidesToShow ? slidesToShow : 4;
            $(this).on('init reInit', function () {
                slickRun.sliderOnInit($(this));
            }).slick({
                slide: '#' + sliderID + ' .grid-tile',
                infinite: true,
                arrows: true,
                swipe: true,
                accessibility: false,
                prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: visibleSlides,
                slidesToScroll: visibleSlides,
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: (visibleSlides - 1),
                            slidesToScroll: (visibleSlides - 1),
                            prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            })
        });
    },
    /**
     * @function productRecomSlider()
     * @description Initializes the product recommendations slider
     * @param Number of Slides to Show in Desktop
     */
    productRecomSlider3: function (slidesToShow) {
        $('.dp-asset-product-1 .product-recom-slider .tiles-container').each(function (idx) {
            var sliderID = 'slider' + idx;
            this.id = sliderID;
            var visibleSlides = slidesToShow ? slidesToShow : 4;
            $(this).on('init', function(){
                slickRun.sliderOnInit($(this));
            }).slick({
                slide: '#' + sliderID + ' .grid-tile',
                infinite: true,
                arrows: true,
                swipe: true,
                accessibility: false,
                prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
                speed: SitePreferences.SLIDE_SHOW_SPEED,
                slidesToShow: visibleSlides,
                slidesToScroll: visibleSlides,
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: (visibleSlides - 1),
                            slidesToScroll: (visibleSlides - 1),
                            prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                            nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                        }
                    }
                ]
            }).on('afterChange', function() {
                slickRun.sliderAfterChange($(this));
            });
        });
    },
    homeOutfitsSlider: function () {
        $('#hp-outfits-7 .tiles-container').on('init', function() {
            slickRun.sliderOnInit($(this));
        }).slick({
            slide: '#hp-outfits-7 .grid-tile',
            infinite: true,
            arrows: true,
            swipe: true,
            accessibility: false,
            prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
            nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        prevArrow: '<button class="slider-prev button-text" type="button"    aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"    aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                }
            ]
        }).on('afterChange', function() {
            slickRun.sliderAfterChange($(this));
        });
    },
    collectionAssetProductSlider: function () {
        var dprecombanner3 = $('.dp-recom-banner-3 .product-recom-slider .tiles-container')
        dprecombanner3.on('init', function(){
            slickRun.sliderOnInit($(this));
        }).slick({
            slide: '.dp-recom-banner-3 .product-recom-slider .grid-tile',
            infinite: true,
            arrows: true,
            swipe: true,
            accessibility: false,
            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                }
            ]
        }).on('afterChange', function() {
            slickRun.sliderAfterChange($(this));
        });
    },
    /**
     * @function dpAssetProductSlider()
     * @description Initializes the product dp-asset-product slider
     */
    dpAssetProductSlider: function () {
        $('#dp-asset-product-1 .tiles-container').not('slick-initialized').slick({
            slide: '#dp-asset-product-1 .tiles-container .grid-tile',
            infinite: true,
            arrows: true,
            swipe: true,
            accessibility: false,
            prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
            nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>',
            speed: SitePreferences.SLIDE_SHOW_SPEED,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        prevArrow: '<button class="slider-prev button-text" type="button"     aria-label="Previous">' + util.svg('Arrow', 'medium') + '</button>',
                        nextArrow: '<button class="slider-next button-text" type="button"     aria-label="Next">' + util.svg('Arrow', 'medium') + '</button>'
                    }
                }
            ]
        });
    },
    sliderOnInit: function(sliderName) {
        sliderName.find('.thumb-link').removeAttr('title');
        sliderName.find('.quickview').removeAttr('title');
        sliderName.find('.slick-list').removeAttr('aria-live');
        //get Slick count
        var slickItems = sliderName.find('.slick-slide:not(.slide-cloned)');
        slickItems.each(function(index) {
            $(this).attr('aria-current',false);
            $(this).find('.slick-slide.slick-current').attr('aria-current',true);
            var productName = $(this).find('.name-link').text();
            $(this).find('.thumb-link').attr('aria-label', 'Product ' + productName + ' ' +  (index + 1) + ' of total ' + slickItems.length + ' products').removeAttr('tabindex');          
            $(this).find('.capture-product-id').attr({
                'aria-hidden': true,
                'tabindex': -1
            });
        })
        //Removing tapindex for all visible items
        var slickItemsA = sliderName.find('.slick-slide.slick-active a');
        setTimeout(function() {
            slickItemsA.each(function() {
                $(this).removeAttr('tabindex')
            });
        }, 100);

        //Removing tabindex for the invisible items
        var slickInvisibleItems = sliderName.find('.slick-slide:not(.slick-active) a');
        setTimeout(function() {
            slickInvisibleItems.each(function() {
                $(this).attr('tabindex',-1);
            });
        },100);

        //Removing Role
        var slicktrack = sliderName.find('.slick-track');
        setTimeout(function() {
            slicktrack.each(function() {
                $(this).removeAttr('role')
            });
        }, 500);

        setTimeout(function() {
            sliderName.find('.slick-slide:not(.slick-cloned)').each(function() {
                $(this).removeAttr('role');
            })
        }, 500);
            
    },
    sliderAfterChange: function(sliderName) {
        sliderName.find('.slick-list').removeAttr('aria-live');
        sliderName.find('.slick-slide.slick-current').attr('aria-current',true);
        sliderName.find('.slick-slide.slick-current .thumb-link').focus();
        var slickItemsA = sliderName.find('.slick-slide.slick-active a');
        //Removing tapindex for all visible items
        setTimeout(function() {
            slickItemsA.each(function() {
                $(this).removeAttr('tabindex');
            });
        }, 500);
        //Removing Role
        var slicktrack = sliderName.find('.slick-track');
        setTimeout(function() {
            slicktrack.each(function() {
                $(this).removeAttr('role')
            });
        }, 500);

        setTimeout(function() {
            sliderName.find('.slick-slide:not(.slick-cloned)').each(function() {
                $(this).removeAttr('role');
            })
        }, 500);

        //Removing tabindex for the invisible items
        var slickInvisibleItems = sliderName.find('.slick-slide:not(.slick-active) a');
        setTimeout(function() {
            slickInvisibleItems.each(function() {
                $(this).attr('tabindex',-1);
            });
        }, 100);
    }
};

module.exports = slickRun;
