'use strict';

/*
 * Function setActivePage()
 * Description sets the menu line to active
 */
function setActivePage() {
    var pageName = $('#activePage').html();
    $('.tab-content ul li a').each(function() {
        if ($(this).html().trim() == pageName) {
            $(this).addClass('active');
        }
    });
}

/*
 * Function initTabs()
 * Description Initializes all instances of tabbed content
 */
function initTabs() {
    if ($('.tabbedContent').length) {
        $('.tabbedContent').each(function () {
            var section = $(this);
            var hash = '.' + window.location.hash.substring(1);

            section.find('.tab-container:first .tabContent').hide();

            // prevent hash check in PDP size chart modal due to PDP hash params
            if ($('.size-chart-modal').length == 0 && hash != '.' && $(hash).length) {
                var activeTab = $(hash).attr('rel');
                section.find('.'+activeTab+'.tabContent').fadeIn();
                section.find('ul.tabs:first li').removeClass('active');
                $(hash).addClass('active');

            } else {
                section.find('.tab-container:first .tabContent:first').show();
            }

            section.find('ul.tabs:first li').click(function() {
                var module = $(this).closest('.tabbedContent');
                module.find('.tab-container:first').children('.tabContent').hide();
                var activeTab = $(this).attr('rel');
                module.find('.tab-container:first').children('.' + activeTab).fadeIn();
                module.find('ul.tabs:first li').removeClass('active');
                $(this).addClass('active');

            });
            var sizechartTab = $('.size-chart .tabbedContent');
            sizechartTab.find('ul.tabs:first li').click(function() {
                var module = $(this).closest('.tabbedContent');
                module.find('.tab-container:first').children('.tabContent').hide();
                var activeTab = $(this).attr('rel');
                module.find('.tab-container:first').children('.' + activeTab).fadeIn();
                module.find('ul.tabs:first li').removeClass('active');
                module.find('ul.tabs:first li button').attr('aria-selected',false);
                module.find('ul.tabs:first li button').attr('tabindex',-1);
                $(this).addClass('active');
                $(this).find('button').attr('aria-selected',true);
                $(this).find('button').attr('tabindex',0);
            });
            var tabs = sizechartTab.find('ul.tabs:first > li');
            sizechartTab.find('ul.tabs:first > li').on('keydown', function(e) {
                var key = e.keyCode;
                var module = $(this).closest('.tabbedContent');
                //Trigger Next li if right key pressed
                if (key === 39) {
                    $(this).next('li').trigger('click');
                    module.find('ul.tabs:first li.active').find('button').focus();
                    return;
                }
                //Trigger Next li if left key pressed
                if (key === 37) {
                    $(this).prev('li').trigger('click');
                    module.find('ul.tabs:first li.active').find('button').focus();
                    return;
                }

                //Trigger End li if left key pressed
                if (key === 35) {
                    tabs[tabs.length - 1].trigger('click');
                    module.find('ul.tabs:first li.active').find('button').focus();
                    return;
                }

                //Trigger Home li if left key pressed
                if (key === 36) {
                    tabs[0].trigger('click');
                    module.find('ul.tabs:first li.active').find('button').focus();
                    return;
                }
            });
        });

    }
}

/*
 * Function initAccordions()
 * Description Initializes all instances of accordion content, aka content in expandable drawers
 */
function initAccordions() {
    if ($('.accordion').length) {
        $('.accordion').each(function () {
            $(this).find('.drawerHeading').click(function() {
                var module = $(this).closest('.accordion');
                module.find('.drawerContent').hide();

                if ($(this).hasClass('drawerActive')) {
                    $(this).removeClass('drawerActive');
                    $(this).attr('aria-selected',false);
                } else {
                    var drawerActiveTab = $(this).attr('rel');
                    module.find('.' + drawerActiveTab).fadeIn();
                    module.find('.drawerHeading').removeClass('drawerActive');
                    $(this).addClass('drawerActive');
                    $(this).attr('aria-selected',true);
                }
            });

        });
    }
}

/*
 * Function initEvents ()
 * Description Initializes the mobile menu
 */
function initEvents () {
    // handle toggle refinement block - Mobile Viewport
    $('#secondary').on('click', '.refinement-header', function () {
        $(this).toggleClass('active');
    });

    $('.pt_customer-service').on('click', '.menu-group-title-expand', function () {
        //collapse all when tapping on open group
        if ($(this).parents('.menu-group').hasClass('active')) {
            $(this).parents('.menu-group').removeClass('active');
        } else {
            // tapping on new group, collapse open group and open new group
            $(this).parents('.menu-group').addClass('active');
        }
    });
}

var customerservice = {
    init: function () {
        setActivePage();
        initEvents();
        initTabs();
        initAccordions();
    }
};

module.exports = customerservice;
