window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var slickRun = require('./slick-ext');
require('jquery.cookie');
$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('janieandjack/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('mirakl/components/miraklNotification'));
    //initCartRecommendations();
    //initCartEmptyRecommendations();
    initSliders();
    initPopovers();
    initRecaptchaCheck();
    floatingBoxInit();
    rotatingBannerInit();
    initDynamicErrorsAlert();
});

require('./thirdParty/bootstrap');
require('base/components/spinner');
require('slick-carousel');

function initSliders() {
    $(".grid-tile:not(:has(*))").remove();
    slickRun.horizontalSlider();
    slickRun.heroSlider();
}

function initCartRecommendations() {
    if (!$('.cart-recommendations .product-recom-slider .product-horizontal-carousel').hasClass('slick-initialized')) {
        slickRun.productRecomSlider(3);
    }
}

function initCartEmptyRecommendations() {
    if (!$('.cart-empty-recommendations .product-recom-slider .tiles-container').hasClass('slick-initialized')) {
        slickRun.productRecomSlider(4);
    }
}

function initPopovers(){
    // init all bootstrap popovers
    $('[data-toggle="popover"]').popover();
    $("html").on("mouseup", function (e) {
        var l = $(e.target);
        if (!l[0].class || l[0].className.indexOf("popover") == -1) {
            $(".popover").each(function () {
                $(this).popover("hide");
            });
        }
    });
}

function initRecaptchaCheck() {
    if($('.g-recaptcha').length > 0){
        $('.g-recaptcha').each(function(e){
            var $recaptchaDiv = $(this);
            var $buttonselector = $(this).data('submitbutton');
            var $buttonEle = $('#' + $buttonselector);
            $buttonEle.on('click', function (e) {
                var	$error = $('#recaptcha-verify-error_'+$buttonselector);
                var captcha = $recaptchaDiv.find('.g-recaptcha-response').val();
                if (captcha.length == 0){
                    e.preventDefault();
                    var error;
                    error = $error.find('span.error');
                    if (error.length === 0) {
                        error = $('<span>').addClass('error').appendTo($error);
                    }
                    error.html(Resources.VALIDATE_CAPTCHA);
                    $error.show();
                    return;
                } else {
                    $error.hide();
                }
            });
        });
    }
}

function floatingBoxInit() {
    var box = $('.floating-box'),
        isBoxCookieSet = $.cookie('floatingBox'),
        scrollTrigger = 400;

    if (box.length && !isBoxCookieSet) {
        $(window).on('scroll', function () {
            floatingBoxShow();
        });

        box.on('click', '.close', function (e) {
            e.preventDefault();

            $.cookie('floatingBox', null, {path: '/'});
            $.cookie('floatingBox', 'assetclose', {path: '/'});

            $('body').removeClass('with-floatingBox');
            box.remove();
        })
    } else {
        box.remove();
    }

    var floatingBoxShow = function () {
        var box = $('.floating-box');

        if (box.length) {
            var scrollTop = $(window).scrollTop();

            if (scrollTop > scrollTrigger) {
                box.addClass('active');
                $('body').addClass('with-floatingBox');
            } else {
                box.removeClass('active');
                $('body').removeClass('with-floatingBox');
            }
        }
    }
}


function rotatingBannerInit() {
    var rotationInterval = SitePreferences.GLOBAL_BANNER_SPEED,
        customCSSFile = $('.rotatingBannerOptions').data('cssfile');

    // load custom CSS
    if (customCSSFile) {
        util.loadCssFile(customCSSFile);
    }

    //Slick initialization
    $('.rotating-banner-slot').on('init', function() {
        $('.rotating-banner-slot').find('.slick-list').removeAttr('aria-live');
        $('.rotating-banner-slot').find('a').removeAttr('title');
        /*setTimeout(function() {
            $('.rotating-banner-slot').find('.slick-track').removeAttr('role');
            $('.rotating-banner-slot').find('.slick-slide').removeAttr('role');
        }, 100);*/
    });

    // vertical infinite slider
    $('.rotating-banner-slot').slick({
        slide: '.navigational-promo-asset',
        infinite: true,
        autoplay: true,
        swipe: false,
        draggable: false,
        arrows: false,
        vertical: true,
        autoplaySpeed: rotationInterval,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        rows: 0
    });
}

function initDynamicErrorsAlert() {
    // Code for screen reader users to navigate through a form using the 'Tab' key

    // Listen for the 'keydown' event on the document
    document.addEventListener('keydown', function (event) {
    // If the key pressed was not 'Tab', ignore the event
    if (event.keyCode !== 9) return;

    // Get the element that the event originated from
    let element = event.target;

    // If the element exists
    if(element) {
        let ariaDescribedby = element.getAttribute('aria-describedby');
        let nextElement = document.getElementById(ariaDescribedby);

        // If the nextElement exists
        if(nextElement) {
            element.blur();
            nextElement.setAttribute('aria-live', 'polite');
            nextElement.setAttribute('tabindex', '0');
        }
    }
    });
}
