/**
 * Function update the Power Review Design
 */
function checkPowerReviewAvailability() {
    if ($('#pr-reviewsnippet').length) {
      if ($('#pr-reviewsnippet .pr-no-reviews').length) {
        $('#pr-reviewsnippet .pr-snippet-stars-reco-stars .pr-snippet-stars-container .pr-snippet-stars').addClass('d-none');
        $('#pr-reviewsnippet .pr-snippet-stars-reco-stars .pr-snippet-read-and-write .pr-snippet-review-count').addClass('d-none');
      } else if ($('#pr-reviewsnippet .pr-review-snippet-container').length) {
        $('#pr-reviewsnippet .pr-snippet-stars-reco-stars .pr-snippet-read-and-write .pr-snippet-write-review-link').addClass('d-none');
  
        var ratings = $('#pr-reviewsnippet .pr-snippet-stars-reco-stars .pr-snippet-stars-container .pr-snippet-rating-decimal').html();
        var reviews = $('#pr-reviewsnippet .pr-snippet-stars-reco-stars .pr-snippet-read-and-write .pr-snippet-review-count').html();
  
        if (ratings && reviews) {
          var ratingsMsg = '<strong> ' + ratings + '</strong><span style="font-size: 80% !important; margin-left: -5px;">(' + reviews + ')</span>';
          $('#pr-reviewsnippet .pr-snippet-stars-reco-stars .pr-snippet-read-and-write .pr-snippet-review-count').html(ratingsMsg);
        }
      }
      $('#pr-reviewsnippet').css('display', 'block');
    }
}

module.exports = {
    checkPowerReviewAvailability: checkPowerReviewAvailability
};